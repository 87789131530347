import React from 'react';
import styles from './PostDetails.module.css';

const PostDetails = ({ date, readingTime }) => {
  return (
    <div className={styles.details}>
      <span className={styles.date}>{date}</span>
      <span className={styles.separator} />
      <span className={styles.readingTime} title={readingTime} />
    </div>
  );
};

export default PostDetails;
